import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SessionStorageService } from "angular-web-storage";
import { ConstantsService } from "src/app/services/constants.service";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import Auth from '@aws-amplify/auth';
import { DynamicFilterService } from "../../shared/dynamic-filter/dynamic-filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";
import { EnviornmentSelectionComponent } from "../../enviornment-selection/enviornment-selection.component";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  
  timeOut: any;
  loggedUser: any = {};

  currentAvatar!: string;
  userLetter: string = '';

  visible: boolean = false;
  hasRoles: boolean = false;
  timedOut: boolean = false;
  sidebarOpened: boolean = false;
  isSideBarPinned: boolean = false;

  env = environment;
  avatar: string = '';
  idleState = 'Not started.';
  currentMenu: string = 'data-management';

  constructor(
    private idle: Idle,
    private router: Router,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private dynamicFilterService:DynamicFilterService,
    private userActivityService: UserActivityService,
    private constantsService: ConstantsService,
  ) {
     this.getUserName();
    }

  ngOnInit(): void {
    let userRole = this.sessionService.getUserRole();
    // if (userRole && Object.keys(userRole).length > 0) {
    //     this.hasRoles = true;
    // } else {
    //     this.hasRoles = false;
    // }

    this.hasRoles = true;

    if (this.sessionStorageService.get('currentMenu')) {
        this.currentMenu = this.sessionStorageService.get('currentMenu') || 'data-management';
    }
  }

  getUserName() {
    let user = this.sessionService.getUser();
    user = user.username;
    this.userLetter = user ? user.charAt(0).toUpperCase() : '';
  }

  setCurrentMenu(currentMenu:any) {
      this.currentMenu = currentMenu;
      this.visible = false;
      this.sessionStorageService.set('currentMenu', currentMenu);
      let userStateData: any = this.userActivityService.getData();
      this.dynamicFilterService.updateFilterValueData(null);
      this.dynamicFilterService.dynamicBadges = [];
      this.dynamicFilterService.setDynamicBadgeValue("","","");
      let key = Object.keys(userStateData.selectedFilters).length
      if(key > 0) {
        this.dynamicFilterService.resetFilter();
      }
  }

  setAvatar() {
      // this.avatar = this.avatarService.getCurrentAvatar();
  }

  logout() {
    Auth.signOut();

    this.dialog.closeAll();
    this.sessionService.setUser(null);
    this.sessionService.setUsername(null);
    this.sessionService.setLoginTime(null);
    this.sessionStorageService.set('currentMenu', null);

    window.location.href = '/';
  }

  setMenuFromURL() {
      let url = this.router.url;

      if (url.indexOf('data-management') > -1) this.currentMenu = 'data-management';
      else if (url.indexOf('bi-reports') > -1) this.currentMenu = 'bi-reports';
      else if (url.indexOf('ui-management') > -1) this.currentMenu = 'ui-management';
      else if (url.indexOf('client-reports') > -1) this.currentMenu = 'client-reports';
      else if (url.indexOf('access-management') > -1) this.currentMenu = 'access-management';
      else if (url.indexOf('operational-reports') > -1) this.currentMenu = 'operational-reports';
      else if (url.indexOf('command-workflow') > -1) this.currentMenu = 'command-workflow';
      else if (url.indexOf('cloning') > -1) this.currentMenu = 'cloning';
      else if (url.indexOf('fixed-files-reports') > -1) this.currentMenu = 'fixed-files-reports';
  }

  openSidebar() {
      this.timeOut = setTimeout(() => {
          this.sidebarOpened = true;
      }, 500);
  }

  closeSidebar() {
      clearTimeout(this.timeOut);
      if (!this.isSideBarPinned) {
          this.sidebarOpened = false;
      }
  }

  sideBarPinned() {
      this.isSideBarPinned = !this.isSideBarPinned;
  }
  toggleList() {
      this.visible = !this.visible;
  }

  arra:any = []

  click() {
    this.arra.push({name :'Main page content here, add more if you want to see the footer push down.'})
  }

  remove(idx:any) {
    this.arra.splice(idx, 1)
  }

  openEnvPopup() {
    // let data = [{'env': 'Dev'},{'env': 'Stage'}]

    let dialogRef = this.dialog.open(EnviornmentSelectionComponent, {
        width: this.constantsService.SMALL_DIALOG_BOX_WIDTH,
        disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
    });

    dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.sessionService.setStoreData('envHeaderVariables', value);

          // URL using next screen
          //this.getModuleList();
        }
    });
  }
}
