import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import * as _ from 'lodash';
import { SessionService } from './session.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedUser: any

  private timeoutId: any;
  private readonly AUTO_LOGOUT_TIME = 20 * 60 * 1000; // 20 minutes in milliseconds
    constructor(
      private router: Router,
      private sessionService: SessionService,
      private dialog: MatDialog
    ) {  this.init();
  }

  /**Auth Related Methods - starts */
  public getToken(): string {
    return sessionStorage.getItem('token') || '';
  }

  public isLoggedIn() {

    const user = this.sessionService.getUserAttributes();
    const userName = this.sessionService.getUsername();
    // check for token expiry, will fail for no token or invalid token
    const token = sessionStorage.getItem('token');

    if(user != null && userName) {
      return true;
    } else {
      return false;
    }
  }
  /**Auth Related Methods - ends */

  // private inactivityTimeout!: ReturnType<typeof setTimeout>;

  // startInactivityTimer() {
  //   this.inactivityTimeout = setTimeout(() => {
  //     // this.logoutAndRedirect();
  //   }, 20 * 60 * 1000);
  // }

  // resetInactivityTimer() {
  //   clearTimeout(this.inactivityTimeout);
  //   this.startInactivityTimer();
  // }

  logoutAndRedirect() {
    Auth.signOut();
    window.location.href = "/timedout";
    sessionStorage.clear();
    this.dialog.closeAll();
    this.sessionService.setUser(null);
    this.sessionService.setUsername(null);
    this.sessionService.setLoginTime(null);

    // window.location.href = "/login";
  }

  // Initialize the auto-logout logic
  init(): void {
    this.resetTimeout(); // Start the timer initially
    this.addActivityListeners(); // Listen to user activity
  }

  // Reset the logout timer
  resetTimeout(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.logoutUser(); // Logout the user after 20 minutes of inactivity
    }, this.AUTO_LOGOUT_TIME);
  }

  // Add event listeners to track user activity
  addActivityListeners(): void {
    const events = ['mousemove', 'click', 'keypress', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, () => this.resetTimeout());
    });
  }

  // Log out the user by redirecting to the login page
  logoutUser(): void {
    this.logoutAndRedirect();
  }

}
