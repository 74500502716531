<!-- Navbar -->
<nav class="navbar-theme navbar navbar-expand-sm navbar-dark flex-nowrap mainHeader navbar-header">
    <div id="side-nav" [ngClass]="{ open: visible, 'd-flex': true, 'd-lg-none': true }" (click)="toggleList()">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <!-- <a class="navbar-brand logo p-0" routerLink="/dashboard/data-management"> -->
    <a routerLink="/dashboard/data-management" (click)="setCurrentMenu('data-management')" name="du-data-management"
        [ngClass]="{ active: currentMenu === 'data-management', 'nav-link': true }">
        <!-- <img src="assets/static-pages/img/logo.png" class="logo" alt="Logo" /> -->
        <!-- <span class="envName" *ngIf="env.name">- {{ env.name }}</span> -->
    </a>

    <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
            <a class="nav-link p-0" data-toggle="dropdown">
                <img *ngIf="currentAvatar" src="{{currentAvatar}}" class="avatar-image" />
                <div *ngIf="!currentAvatar" id="profileImage" [innerHTML]="userLetter"></div>
                <span class="ti-angle-down"></span>
            </a>

            <div class="dropdown-menu">
                <a class="dropdown-item" routerLink="/dashboard/personalize" *ngIf="hasRoles">Personalize</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openEnvPopup()">Switch Region</a>
                <a class="dropdown-item" href="javascript:void(0)"
                    *ngIf="hasRoles">Change Password</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Log Out</a>
            </div>
        </li>
    </ul>
</nav>

<!-- Left Menu -->
<main class="d-flex theme-layout" *ngIf="hasRoles">
    <aside class="theme-sidebar" [ngClass]="{ opened: sidebarOpened, 'sidenav-bar': visible }"
        (mouseenter)="openSidebar()" (mouseleave)="closeSidebar()">
        <div class="pin-sidebar" *ngIf="sidebarOpened" (click)="sideBarPinned()"
            [ngClass]="{ sideBarPinned: isSideBarPinned }">
            <i class="fa fa-thumb-tack" aria-hidden="true"></i>
        </div>

        <div class="sidebar-links mt-md-4">
            <ul class="sidebar-links-list pt-2">
                <li (click)="closeSidebar()">
                    <a routerLink="/dashboard/data-management" (click)="setCurrentMenu('data-management')"
                        name="du-data-management"
                        [ngClass]="{ active: currentMenu === 'data-management', 'nav-link': true }"><i
                            class="fa fa-pie-chart" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }">Data Management </span>
                    </a>
                </li>
                <li (click)="closeSidebar()">
                    <a routerLink="/dashboard/ui-management" (click)="setCurrentMenu('ui-management')"
                        name="du-ui-management"
                        [ngClass]="{ active: currentMenu === 'ui-management', 'nav-link': true }"><i class="fa fa-th"
                            aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> UI Management </span>
                    </a>
                </li>
                <li (click)="closeSidebar()">
                    <a routerLink="/dashboard/access-management" (click)="setCurrentMenu('access-management')"
                        name="du-access-management"
                        [ngClass]="{ active: currentMenu === 'access-management', 'nav-link': true }"><i
                            class="fa fa-lock" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> Access Management </span></a>
                </li>
                <li (click)="closeSidebar()">
                    <a routerLink="/dashboard/bi-reports" (click)="setCurrentMenu('bi-reports')" name="du-bi-reports"
                        [ngClass]="{ active: currentMenu === 'bi-reports', 'nav-link': true }"><i
                            class="fa fa-bar-chart" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> BI Reports </span></a>
                </li>
                <li (click)="closeSidebar()">
                    <a routerLink="/aggregation/operational-reports" (click)="setCurrentMenu('operational-reports')"
                        name="du-operational"
                        [ngClass]="{ active: currentMenu === 'operational-reports', 'nav-link': true }"><i
                            class="fa fa-line-chart" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> Operational Reports </span></a>
                </li>
                <li (click)="closeSidebar()">
                    <a routerLink="/dashboard/client-reports" (click)="setCurrentMenu('client-reports')"
                        name="du-client" [ngClass]="{ active: currentMenu === 'client-reports', 'nav-link': true }"><i
                            class="fa fa-pie-chart" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> Client Reports </span></a>
                </li>
                <li (click)="closeSidebar()">
                    <a routerLink="/dashboard/command-workflow" (click)="setCurrentMenu('command-workflow')"
                        name="du-process-management"
                        [ngClass]="{ active: currentMenu === 'command-workflow', 'nav-link': true }"><i
                            class="fa fa-terminal" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> Process Management </span></a>
                </li>
                <li (click)="closeSidebar()">
                    <a routerLink="/dashboard/cloning-work-flow" (click)="setCurrentMenu('cloning-work-flow')"
                        name="du-cloning"
                        [ngClass]="{ active: currentMenu === 'cloning-work-flow', 'nav-link': true }"><i
                            class="fa fa-copy" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> Cloning </span></a>
                </li>

                <li (click)="closeSidebar()">
                    <a routerLink="/aggregation/fixed-files-reports" (click)="setCurrentMenu('fixed-files-reports')"
                        name="du-fixed-files"
                        [ngClass]="{ active: currentMenu === 'fixed-files-reports', 'nav-link': true }"><i
                            class="fa fa-copy" aria-hidden="true"></i>
                        <span [ngClass]="{ openedMenu: sidebarOpened }"> Fixed Files </span></a>
                </li>
            </ul>
        </div>
        <div [ngClass]="{ 'mm-overlay': visible }"></div>
    </aside>
    <section class="theme-page dashboard-data" [ngClass]="{ wrapperopened: sidebarOpened && isSideBarPinned }">
        <router-outlet></router-outlet>
    </section>
</main>

<main class="d-flex theme-layout no-access" *ngIf="!hasRoles">
    <h1>You don't have access to the application</h1>
</main>

<div class="copyright-area">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="copyright-item text-center">
                <p>Copyright ©2024 Data Unveil</p>
            </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-12 pull-right">
            <ul class="terms-privacy">
                <li><a href="" target="_blank">Terms of use</a></li>
                <li><a href="" target="_blank">Disclaimer</a></li>
                <li><a href="" target="_blank">Privacy policy</a></li>
            </ul>
        </div> -->
    </div>
</div>
