import { Component, Input, OnInit } from "@angular/core";
import { DynamicFilterService } from "../dynamic-filter/dynamic-filter.service";
import moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-filter-badges",
  templateUrl: "./filter-badges.component.html",
  styleUrls: ["./filter-badges.component.scss"],
})
export class FilterBadgesComponent implements OnInit {
  moment = moment;
  localData: any = [];
  @Input() filterNames: string[] = [];
  filterResponse: any = [];
  isDynamicFilterBadges:boolean = false;
  constructor(public dynamicFilterService: DynamicFilterService,private router: Router,) {
    this.getRoute()
  }

  getRoute() {
    let url = this.router.url;
    this.isDynamicFilterBadges = url.includes('Dynamic-Process-Monitor');
    console.log("this.isBurgerMenu",this.isDynamicFilterBadges);
    
  }
  ngOnInit(): void {
    console.log("this.dynamicFilterService.badges",this.dynamicFilterService.badges);
    
  }
  
  selectedValues(event: any) {
    this.localData = [];

    if (event.type != "date") {
      if (event.selectedOpt instanceof Array) {
        event.selectedOpt.forEach((res: any) => {
          if (res[event.labelProp]) {
            let a = {
              key: event.labelProp,
              value: res[event.labelProp],
            };
            this.localData.push(a);
          }
        });
      } else {
        let a = {
          key: event.labelProp,
          value: event.selectedOpt[event.labelProp],
        };
        this.localData.push(a);
      }
    } else {
      this.localData = [];
    }
  }

  resetFilter() {
    this.dynamicFilterService.resetFilter();
  }

  filterBaches(setFilter:any) {
    let filterNames = setFilter.filter((badge:any)=> badge.type != 'hidden');
   // console.log("filter batches ", filterNames);
    return filterNames; 
  }

  ngOnDestroy() {
    // Reset the shared data when the component is destroyed
    this.dynamicFilterService.updateFilterValueData(null);
  }
}
